import { OperationVariables, useMutation, useQuery } from "@apollo/client";
import { INITIATE_PAYMENT, PERMISSION_ACTION_MESSAGE, VERIFY_PAYMENT } from "src/constants";
import { GET_BILLING_INVOICE, GET_BILLING_INVOICES } from "src/constants/Queries/billing-invoice";
import { useApolloSearch } from "src/hooks";
import { useRowData, useStaff, useToast } from "src/state";
import { GetBillingInvoiceResponse, GetBillingInvoicesResponse } from "src/types";
import { contentsToUrl, downloadCSV, generateCsvContent, getModalParams } from "src/utils";


interface InitiatePayment { 
  invoiceId: string, 
  callbackUrl: string
}


const useBilling = () => {
  const { hasPermission } = useStaff()
  const { showToast } =  useToast()
  const id = getModalParams("id");
  
  const useGetBillingInvoices = (value?: OperationVariables, skip?: boolean) => {
    const { searchValue, ...others } = useApolloSearch();
    const result = useQuery<GetBillingInvoicesResponse>(GET_BILLING_INVOICES, {
      errorPolicy: "all",
      skip,
      variables: { 
        input: {
          ...value,
          search: searchValue || undefined,
        },
      }
    });


    const exportDataToCSV = () => {
      const dataToExport = result?.data?.getBillingInvoices?.invoices?.map(invoice => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { staff, subscription, ...rest } = invoice;
        return rest;
      });
      const csvContents = [generateCsvContent(dataToExport as unknown as Record<string, unknown>[], "Billing History")]
      const url = contentsToUrl('Billing Invoice', csvContents)
      downloadCSV(url, 'Billing Invoice')
    }

    return { ...result , searchValue, exportDataToCSV, ...others};
  };

  const useGetBillingInvoice = (value?: string, skip?: boolean) => {
    const { getDataByKey, updateRowData } = useRowData();
    const billing_data = getDataByKey("billing")
    
    const result = useQuery<GetBillingInvoiceResponse>(GET_BILLING_INVOICE, {
      errorPolicy: "all",
      skip: skip || !!billing_data,
      variables: { input: value || id },
      onCompleted: (data) => updateRowData('billing', data?.getBillingInvoice)
    });

    return { ...result };
  };

  const useInitiatePayment = () => {
    const [execute, result] = useMutation(INITIATE_PAYMENT);

    const initiatePayment = ({ invoiceId, callbackUrl }: InitiatePayment) => {

      if (!hasPermission("MAKE_SUBSCRIPTION_PAYMENT")) return showToast(PERMISSION_ACTION_MESSAGE, 'warning'); 
      return execute({
        variables: { input: {
          invoiceId, callbackUrl
        }},
        onCompleted: (data) => {
          if (data) {
            showToast(data.initiatePayment.message, 'success')
            window.location.href =  data.initiatePayment.data.payment.authorization_url
          }
        },
        onError: (err) => showToast(err.message, "error")
      });
    }

    return { ...result, execute, initiatePayment };
  };
  

  const useVerifyPayment = (value: string, skip?: boolean) => {
    const result = useQuery(VERIFY_PAYMENT, {
      errorPolicy: "all",
      skip,
      variables: { reference: value },
      pollInterval: 5
    });

    return { ...result };
  };


  return {
    useGetBillingInvoices,
    useGetBillingInvoice,
    useInitiatePayment,
    useVerifyPayment
  }
}

export { useBilling };