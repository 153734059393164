import {
  Button,
  Column,
  ConfirmationPrompt,
  H6,
  Pill,
  Row,
} from 'src/components';
import moment from 'moment';
import { Modal } from 'src/containers';
import { currencyformatter, removeModalHash } from 'src/utils';
import { useRowData } from 'src/state';
import { BillingInvoice } from 'src/types/billing.type';
import { BILLING_INVOICE_STATUS } from 'src/constants';
import { CheckCancelIcon, CheckCircleIcon } from 'src/assets/icons';
import { useLocation } from 'wouter';
import { useEffect } from 'react';
import SummaryInfo from '../PatientDetails/Invoice/SummaryInfo';
import SummaryTable from './components/SummaryTable';
import { useBilling } from './api';
import { OverlayLoader } from './components/overlay-loader';


const ConfirmPaidModal = () => {
  const { getDataByKey } = useRowData();
  const [, setLocation] = useLocation()
  const { useGetBillingInvoice, useInitiatePayment, useVerifyPayment } = useBilling();
  useGetBillingInvoice()
  const { initiatePayment, loading: paymentLoading} = useInitiatePayment()
  const billingInvoice = getDataByKey("billing") as BillingInvoice;
  const searchParams = new URLSearchParams(window.location.search);
  const trxref = searchParams.get('trxref');


  

  const { status, invoiceId, netAmount: amount, createdAt, dueDate, paidAt, id, payment, staff } = billingInvoice || {}
  const verifyPayment = useVerifyPayment(payment?.reference as string, 
    !payment?.reference || status?.toLowerCase() === "paid"
  )
  const paymentStatus = verifyPayment?.data?.verifyPayment?.data?.status?.toLowerCase()
  const isCompleted = (paymentStatus === "success" || paymentStatus === 'failed' || paymentStatus === "abandoned" || paymentStatus === "reversed") 

  useEffect(() => {
    if (payment?.reference && isCompleted) {
      verifyPayment.stopPolling()
    }
  }, [isCompleted, payment?.reference, verifyPayment])


  return (
    <Modal width='50rem'>
      {(!isCompleted || (isCompleted && !trxref)) ? (
        <Column position='relative' width="100%" height="100%" gap={0.5}>
          {(verifyPayment.loading || !billingInvoice) && <OverlayLoader />}
          <Row justify="space-between">
            <H6>
              Invoice Summary
            </H6>
            <Pill 
              data-testid="invoice-status" 
              color={BILLING_INVOICE_STATUS[status as keyof typeof BILLING_INVOICE_STATUS]} 
              outlined
            >
              {status}
            </Pill>
          </Row>
          <Row justify="space-between">
            <SummaryInfo
              label="INVOICE ID"
              value={invoiceId}
            />
            <SummaryInfo
              label="Total Amount Due"
              value={currencyformatter.format(amount)}
            />
            <SummaryInfo
              label={status?.toLowerCase() !== "paid" ? "Payment Status" : "Billing Admin"}
              value={status?.toLowerCase() !== "paid" ? status : `${staff?.user?.lastName} ${staff?.user?.firstName}`}
            />
            {payment?.reference && (
              <SummaryInfo
                label="Transaction Status"
                value={paymentStatus}
              />
            )}
          </Row>
          <Row justify="space-between">
            <SummaryInfo
              label="Date Generated"
              value={moment(createdAt || '').format(
                'h:mm a, MMM. D, YYYY',
              )}
            />
            <SummaryInfo
              label={status?.toLowerCase() !== "paid" ? "Due Date" : "Payment Date"}
              value={moment(status?.toLowerCase() !== "paid" ? dueDate : paidAt || '').format(
                'MMM. D, YYYY',
              )}
            />
            {status?.toLowerCase() !== "paid" ? <Row /> : (
              <SummaryInfo
                label="Email Address"
                value={staff?.user?.emails?.[0]}
              />
            )}
            {payment?.reference && (
              <Row />
            )}
          </Row>
          <SummaryTable />
          <Button
            data-testid="confirm-paid"
            isLoading={paymentLoading}
            width="max-content"
            modStyles={{ ml: "auto", mt: '2rem' }}
            onClick={() => {
              if (status?.toLowerCase() !== "paid") {
                return initiatePayment({ invoiceId: id, callbackUrl: window.location.href })
              }
              return setLocation(`${window.location.pathname}#billing-invoice&id=${id}`)
            }}
          >
            {status?.toLowerCase() === "paid" ? "Print Invoice" : "Pay Now"}
          </Button>
        </Column>
      ) : (
        <ConfirmationPrompt
          title={`Payment ${paymentStatus === "success" ? 'Successful' : 'Failed'}`}
          icon={paymentStatus === "success" ? <CheckCircleIcon /> : <CheckCancelIcon />}
          message={paymentStatus === "success" ? "Your payment has been processed successfully!" : "Your payment failed, please try again!"}
          onConfirm={async () => {
            if (paymentStatus === "success") {
              removeModalHash()
              setLocation(`${window.location.pathname}#billing-invoice&id=${id}`)
              return;
            }
            await initiatePayment({ invoiceId: id, callbackUrl: window.location.href });
          }}
          onDismiss={removeModalHash}
          confirmText={paymentStatus === "success" ? "View Invoice" : "Try Again"}
          dismissText="Close"
        />
      )}
    </Modal>
  );
};
export default ConfirmPaidModal;
