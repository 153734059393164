import { gql } from '@apollo/client';

export const INITIATE_PAYMENT = gql`
  mutation InitiatePayment($input: InitiatePaymentInput) {
    initiatePayment(input: $input) {
      data
      message
    }
  }
`;

