import { Avatar, Button, Column, H6, Row, Text } from 'src/components';
import moment from 'moment';
import SummaryInfo from 'src/pages/admin/PatientDetails/Invoice/SummaryInfo';
import mixpanel from 'mixpanel-browser';
import { useRowData, useUser } from 'src/state';
import { Modal } from 'src/containers';
import { invoiceStyles, printContent, theme } from 'src/utils';
import { BillingInvoice } from 'src/types';
import { useRef } from 'react';
import SummaryTable from './SummaryTable';
import { useBilling } from '../api';
import { OverlayLoader } from './overlay-loader';
import { BorderColumn } from '../styled';





const BillingInvoicePreview = () => {
  const { getDataByKey } = useRowData();
  const { useGetBillingInvoice } = useBilling();
  useGetBillingInvoice()  
  const currentInvoice = (getDataByKey("billing") || {}) as BillingInvoice;
  const { user } = useUser()
  const organisation = user?.getUser?.staff?.[0]?.facility?.organisation || {}
  const invoiceRef = useRef<HTMLDivElement>(null);


  return (
    <Modal width='32rem'>
      <BorderColumn 
        position='relative' 
        width="100%" 
        height="100%" 
        gap={0.5} 
        ref={invoiceRef} 
        id="print-invoice"
      >
        {!currentInvoice && <OverlayLoader />}
        <Row 
          background={theme.grey[100]} 
          width="100%"
          justify="space-between"
          modStyles={{ pt: `30px`, pb: `15px`, px: `30px`, br: `6px`, pa: 0 }}
        >
          <Avatar modStyles={{ pa: 0 }} size={52} src={organisation?.logo || organisation?.name} />
          <Column width='max-content'>
            <H6 modStyles={{ my: 0.25 }}>INVOICE</H6>
            {currentInvoice?.invoiceId && (
              <Text size="xs" color={theme.secondary[700]} modStyles={{ my: 0 }} data-testid="invoice-id">
                {currentInvoice?.invoiceId}
              </Text>
            )}
          </Column>
        </Row>
        {currentInvoice && (
          <Column width="100%" gap={1} modStyles={{ px: `1.875rem`, my: 1 }}>
            <Row justify="space-between">
              <SummaryInfo
                label="Invoice To"
                value={organisation?.name}
              />
              <SummaryInfo
                label="Payment Method"
                value={currentInvoice?.payment?.provider as string}
              />
            </Row>
            <Row justify="space-between">
              <SummaryInfo
                label="Date Generated"
                value={moment(currentInvoice?.createdAt).format('h:mma, MMM. D, YYYY')}
              />
              <SummaryInfo
                label="Payment Date"
                value={moment(currentInvoice?.paidAt).format('h:mma, MMM. D, YYYY')}
              />
            </Row>
            <Row justify="space-between">
              <SummaryInfo
                label="Paid By"
                value={`${currentInvoice?.staff?.user?.lastName} ${currentInvoice?.staff?.user?.firstName}`}
              />
              <SummaryInfo
                label="Email Address"
                value={currentInvoice?.staff?.user?.emails?.[0]}
              />
            </Row>
          </Column>
        )}
        <SummaryTable />
        <Column modStyles={{ px: 1.875, mb: 6 }}>
          <Text size="xs" weight="bold" color={theme.grey[900]}>Note</Text>
          <Text size="xs" weight="bold" color={theme.primary[700]} modStyles={{ my: 0 }}>
            Invoice is an itemized commercial document that records the products or services delivered to facility mentioned on this document by Ankora Health Limited, the total amount due, and the preferred payment method.
          </Text>
        </Column>
      </BorderColumn>
      <Button
        data-testid="print-invoice"
        width="max-content"
        onClick={() => {
          mixpanel.track(`Click 'Print Receipt' button`, {
            feature: 'Inventory Invoice',
          });
          printContent(invoiceRef, invoiceStyles)
        }}
        modStyles={{ mt: 1.25 }}
      >
        Print Invoice
      </Button>
    </Modal>
  );
};

export default BillingInvoicePreview;
