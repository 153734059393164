import { Chip, Column } from 'src/components';
import { font, theme } from 'src/utils/variables';
import styled from 'styled-components';


export const UpgradeChip = styled(Chip)`
  background-color: ${theme.secondary[600]};
  color: white;
  font-size: ${font.sizes.base};
  border-radius: 999px;
  border: 0;
  font-weight: 500;
  paddding: 2px 10px;
`;

export const BorderColumn = styled(Column)`
  border: 1px solid ${theme.grey[200]};
  border-radius: 6px;
  border-bottom: 10px solid ${theme.accent[700]};
  table > thead > tr > th:first-child {
    padding-left: 30px;
  }
  table > thead > tr > th:last-child {
    padding-right: 30px;
  }
  table > tbody > tr > td:last-of-type {
    padding-right: 30px;
  }
  table > tbody > tr > td:first-of-type {
    padding-left: 30px;
  }
  & > div > div > div > span {
    font-size: 12px;
  }
  table > thead > tr > th {
    font-size: 12px;
  }
  table > tbody > tr > td {
    font-size: 13px;
  }
`